import { Box, Grid, IconButton } from '@mui/material';
import background from '../../ui/images/leavesBackgroundBlured.png';
import sienkiewicz from '../../ui/images/sienkiewicz.png';
import logo from '../../ui/images/olijp.png';
import MainButton from '../UI/MainButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useContext, useState } from 'react';
import { ClickAwayListener } from '@mui/base';
import AuthContext from '../../store/auth-context';
import {
	districtsPage,
	olympicsSettingsPage,
	olympicsStagesPage,
	schoolsPage,
	studentsPage,
	teachersPage,
} from '../../store/fixed-routing';

type Props = {
	setIndex: (index: number) => void;
};
const PanelMenu = ({ setIndex }: Props) => {
	const [open, setOpen] = useState(false);
	const { isAdmin, isTeacher, isSecretary } = useContext(AuthContext);

	return (
		<ClickAwayListener onClickAway={() => setOpen(false)}>
			<Box
				sx={{
					height: { xs: open ? '100%' : 'auto', md: '100%' },
					width: '200px',
					position: 'fixed',
					top: 0,
					left: 0,
				}}
			>
				<IconButton
					sx={{
						position: 'fixed',
						top: '10px',
						left: '10px',
						display: { xs: open ? 'none' : 'block', md: 'none' },
					}}
					onClick={() => setOpen(true)}
				>
					<MenuIcon />
				</IconButton>
				<Grid
					item
					container
					flexWrap={'nowrap'}
					flexDirection={'column'}
					component={'aside'}
					width={'200px'}
					height={'100%'}
					sx={{
						position: { xs: 'fixed', md: 'relative' },
						top: 0,
						left: 0,
						transition: 'transform .2s',
						transform: { xs: open ? 'none' : 'translateX(-200px)', md: 'none' },
						padding: '10px',
						background: `url(${background})`,
						boxShadow: '10px 0 20px -10px',
					}}
				>
					<Grid
						item
						container
						justifyContent={'center'}
						rowGap={1}
					>
						<Box
							sx={{
								maxWidth: '100%',
							}}
							component={'img'}
							src={sienkiewicz}
						/>
						<Box
							sx={{
								maxWidth: '100%',
							}}
							component={'img'}
							src={logo}
						/>
					</Grid>
					<Grid
						item
						container
						flexDirection={'column'}
						justifyContent={'center'}
						flexGrow={1}
						rowGap={4}
						sx={{
							width: '100%',
						}}
					>
						<MainButton
							darkVersion={true}
							title={'Okręgi'}
							sx={{ p: 0 }}
							href={districtsPage}
						/>
						<MainButton
							darkVersion={true}
							sx={{ p: 0 }}
							title={'Szkoły'}
							href={schoolsPage}
						/>
						{isSecretary && (
							<MainButton
								darkVersion
								sx={{ p: 0 }}
								title={'Nauczyciele'}
								href={teachersPage}
							/>
						)}
						{(isSecretary || isTeacher) && (
							<MainButton
								darkVersion
								sx={{ p: 0 }}
								title={'Uczniowie'}
								href={studentsPage}
							/>
						)}
						{isSecretary && (
							<MainButton
								darkVersion
								sx={{ p: 0 }}
								title={'Etapy'}
								href={olympicsStagesPage}
							/>
						)}
						{isAdmin && (
							<MainButton
								darkVersion
								sx={{ p: 0 }}
								title={'Ustawienia'}
								href={olympicsSettingsPage}
							/>
						)}
					</Grid>
				</Grid>
			</Box>
		</ClickAwayListener>
	);
};

export default PanelMenu;
